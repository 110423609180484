import React from "react";
import styled from "styled-components";

import { withPrefix } from "gatsby";

import { DATA } from "../data";
import { MOBILE_BREAKPOINT } from "../style";

const HeaderWrapper = styled.div`
  width: 100%;
  background-color: ${DATA.rgb};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
`;

const HeaderContent = styled.div`
  height: 48px;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: row;
  padding: 0 16px;
  align-items: center;
`;

const LogoWrapper = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Logo = styled.img`
  width: 24px;
  filter: invert(100%);
`;

const TextLogo = styled.img`
  width: 128px;
  margin-left: 16px;
`;

const NavigationWrapper = styled.div`
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: none;
  }
`;

const NavigationItem = styled.a`
  font-size: 15px;
  text-transform: uppercase;
  padding: 0 16px;
  cursor: pointer;

  &:hover {
    color: white;
  }
`;

const MobileMenuButton = styled.div`
  padding: 8px;

  @media (min-width: ${MOBILE_BREAKPOINT}) {
    display: none;
  }
`;

const MenuIcon = styled.img`
  width: 24px;
`;

const MobileMenuWrapper = styled.div`
  transition: all ease 0.5s;
  width: 100%;
  overflow: hidden;
  height: ${props => (props.show ? "288px" : 0)};
  background-color: ${DATA.color};
  position: absolute;
  padding: ${props => (props.show ? "16px 0" : 0)};
  top: 48px;
  left: 0;
`;

const MobileNavItem = styled.a`
  display: block;
  width: 100%;
  line-height: 64px;
  font-size: 28px;
  text-align: center;
  height: 64px;
`;

export class Header extends React.Component {
  state = {
    menuOpen: false
  };

  render() {
    const navigation = [
      "Mission",
      "Services",
      "Leadership",
      "Contact"
    ];

    return (
      <HeaderWrapper>
        <HeaderContent>
          <MobileMenuWrapper show={this.state.menuOpen}>
            {navigation.map(n => (
              <MobileNavItem
                key={n}
                onClick={() => {
                  if (window.location.pathname !== "/") {
                    window.location.href = "/";
                  }
                  else {
                      document.getElementById(n).scrollIntoView({
                        behavior: "smooth"
                      });
                  }
                  this.setState({ menuOpen: false });
                }}
              >
                {n}
              </MobileNavItem>
            ))}
          </MobileMenuWrapper>
          <LogoWrapper
            onClick={() => {
              if (window.location.pathname !== "/") {
                window.location.href = "/";
              }
              document.body.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
              });
            }}
          >
            <Logo src={withPrefix(`/${DATA.logoBw}`)} />
            <TextLogo src={withPrefix(`/${DATA.textLogo}`)} />
          </LogoWrapper>
          <div style={{ flex: 1 }} />
          <NavigationWrapper>
            {navigation.map(n => (
              <NavigationItem
                key={n}
                onClick={() => {
                  if (window.location.pathname !== "/") {
                    window.location.href = "/";
                  }
                  else {
                    document.getElementById(n).scrollIntoView({
                        behavior: "smooth"
                    });
                  }
                }}
              >
                {n}
              </NavigationItem>
            ))}
          </NavigationWrapper>
          <MobileMenuButton
            onClick={() => this.setState({ menuOpen: !this.state.menuOpen })}
          >
            <MenuIcon src={withPrefix(`/${DATA.logoMenu}`)} />
          </MobileMenuButton>
        </HeaderContent>
      </HeaderWrapper>
    );
  }
}
