import { createGlobalStyle } from "styled-components";

export const Globalstyle = createGlobalStyle`

  h1, h2, h3, h4, h5, h6, li, a, span, button {
    font-family: 'Carrois Gothic', sans-serif;
    margin: 0;
  }

  a {
    color: black;
    text-decoration: none;
  }

  p {
    font-family: 'Open Sans', sans-serif;
  }

  * {
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch
  }

  html {
    overflow: hidden;
  }

  body, html {
    height: 100%;
    margin: 0;
    background-color: #f0f3f5;
    width: 100%;

  }

  body {
   overflow-y: auto;
  }

  p {
    margin: 0;
  }
`;
